.search-box {
  @apply h-56 px-4 pt-11 pb-3 bg-gray-100;
  background-image: url('/images/ruang-gtk/header-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.search-box input {
  @apply border-0 !h-12 rounded-full text-white text-base placeholder-white;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6px);
}

.search-box button {
  @apply !border-0 !border-transparent !h-12 !w-12 !bg-transparent hover:!bg-transparent rounded-full !shadow-none;
  top: 1px;
  right: 1px;
}

.search-box button i {
  @apply !text-white;
}

.home-banners {
  @apply relative;
}

.home-banners::before {
  @apply absolute top-0 left-0 h-full z-10;
  content: '';
  width: 15px;
  background-color: #fbfdfe;
}
